import { GridColDef, GridRowsProp } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import axios from 'axios';
import Table from '../../common/components/Table';
import { PAGE_SIZE } from '../../common/constants/page-size';
import { BaseResponse } from '../../common/interfaces/base-response';
import RowOptionsButton from '../../common/components/RowOptionsButton';
import { ItemType } from '../../common/enums/item-type';
import GroupFormModal from '../../common/components/FormModals/GroupFormModal';
import { Group } from '../../common/entities/Group';
import CustomSeparator from '../../common/components/BreadCrumbs';
import MamamHeader from '../../common/components/MamamH3';

export default function Groups() {
	const [page, setPage] = useState(0);
	const [searchOptions, setSearchOptions] = useState('');
	const [sendReq, setSendReq] = useState(false);
	const [open, setOpen] = useState(false);
	const [groups, setGroups] = useState<BaseResponse<Group>>();
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setLoading(true);
		axios
			.request({
				method: 'GET',
				url: `/groups/list?page=${page}&limit=${PAGE_SIZE}${searchOptions}`,
			})
			.then((response) => {
				setGroups(response.data);
			})
			.catch((error) => console.error(error))
			.finally(() => setLoading(false));
	}, [page, searchOptions, sendReq]);

	const applyFilters = (filters: string) => {
		setSearchOptions(filters);
		setSendReq(!sendReq);
	};

	const handlePageChange = (pageNumber: number) => {
		setPage(pageNumber);
		setSendReq(!sendReq);
	};

	const handleClickAdd = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleSave = () => {
		setSendReq(!sendReq);
	};

	const rows: GridRowsProp =
		groups?.data?.map((row) => ({ ...row, options: row.id })) || [];

	const columns: GridColDef[] = [
		{
			field: 'options',
			headerName: `${groups?.count || 0}`,
			width: 100,
			cellClassName: 'table-options',
			headerClassName: 'table-options-header',
			renderCell(params) {
				return (
					<RowOptionsButton
						itemId={params.value}
						handleSave={handleSave}
						tabIndex={params.tabIndex}
						itemType={ItemType.GROUP}
						is_active={params.row.is_active}
					/>
				);
			},
		},
		{ field: 'name', headerName: '', width: 420 },
		{ field: 'address', headerName: 'Dirección', width: 220 },
	];

	return (
		<div>
			{open && (
				<GroupFormModal
					open={open}
					handleClose={handleClose}
					handleSaveParent={handleSave}
					title="Agregar grupo"
					parentType={ItemType.GROUP}
				/>
			)}
			<CustomSeparator crumbs={[{ name: 'Grupos', path: '/grupos' }]} />
			<MamamHeader text="Grupos" marginBlock="1.5rem" />
			<Table
				rows={rows}
				columns={columns}
				loading={loading || !groups?.data}
				onPageChange={handlePageChange}
				rowCount={groups?.count}
				applyFilters={applyFilters}
				handleClickAdd={handleClickAdd}
				itemType={ItemType.GROUP}
			></Table>
		</div>
	);
}
