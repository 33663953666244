import styled from '@emotion/styled';
import LogoEntero from '../../common/assets/logo-entero.png';
import LogoCorto from '../../common/assets/logo-mamam.png';

import { useEffect, useState } from 'react';

const WINDOW_RESIZE = 1000;
const LogoImg = styled('img')(() => ({
	width: '60%',
	marginTop: '1rem',
	marginInline: 'auto',
	height: 'auto',
	maxHeight: '500px',
	minHeight: '100px',
	'@media(max-width:1000px)': {
		minWidth: '80%',
	},
}));
export default function Home() {
	const [windowSize, setWindowSize] = useState([
		window.innerWidth,
		window.innerHeight,
	]);
	useEffect(() => {
		const handleWindowResize = () => {
			setWindowSize([window.innerWidth, window.innerHeight]);
		};

		window.addEventListener('resize', handleWindowResize);

		return () => {
			window.removeEventListener('resize', handleWindowResize);
		};
	}, []);
	return (
		<div style={{ display: 'flex', height: '50vh', alignItems: 'center' }}>
			<LogoImg
				src={windowSize[0] < WINDOW_RESIZE ? LogoCorto : LogoEntero}
				alt="logo-mamam"
			/>
		</div>
	);
}
