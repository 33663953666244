import { useEffect } from 'react';
import { RouterProvider } from 'react-router';
import { createBrowserRouter } from 'react-router-dom';

import Login from './pages/Login';
import { useToken, useTokenUpdate } from './common/contexts/tokenContext';
import { refreshToken } from './common/requests/refresh-token';
import { useUser, useUserUpdate } from './common/contexts/userContext';
import { useGetUser } from './common/requests/get-me';
import Members from './pages/Members';
import Layout from './pages/Layout';
import Interested from './pages/Interested';
import axios from 'axios';
import GroupMembers from './pages/GroupMembers';
import Groups from './pages/Groups';
import Coordinators from './pages/Coordinators';
import GroupTypes from './pages/GroupTypes';
import States from './pages/States';
import RestorePassword from './pages/RestorePassword';
import ChangePassword from './pages/ChangePassword';
import MemberDetails from './pages/MemberDetails';
import CoordinatorDetails from './pages/CoordinatorDetails';
import RequireAdmin from './common/validations/require-admin';
import OwnsGroup from './common/validations/owns-group';
import GroupsList from './pages/GroupsList';
import Home from './pages/Home';

const REFRESH_TOKEN_INTERVAL = 1000 * 60 * 10;

function App() {
	const token = useToken();
	const setToken = useTokenUpdate();
	const user = useUser();
	const setUser = useUserUpdate();
	const getUser = useGetUser();

	axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;

	useEffect(() => {
		refreshToken().then((newToken) => {
			setToken(newToken);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!user) {
			getUser().then((currentUser) => {
				setUser(currentUser);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [token]);

	useEffect(() => {
		setInterval(() => {
			refreshToken().then((newToken) => {
				setToken(newToken);
			});
		}, REFRESH_TOKEN_INTERVAL);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const router = createBrowserRouter([
		{
			path: '/',
			element: !token?.accessToken ? <Login /> : <Layout />,
			children: [
				{
					path: '/',
					element: <Home />,
				},
				{
					path: '/miembros',
					element: (
						<RequireAdmin>
							<Members />
						</RequireAdmin>
					),
				},
				{
					path: '/miembros/:id',
					element: <MemberDetails />,
				},
				{
					path: '/interesadas',
					element: (
						<RequireAdmin>
							<Interested />
						</RequireAdmin>
					),
				},
				{
					path: '/grupos',
					element: (
						<RequireAdmin>
							<Groups />
						</RequireAdmin>
					),
				},
				{
					path: '/coordinadoras/:id',
					element: <CoordinatorDetails />,
				},
				{
					path: '/coordinadoras',
					element: (
						<RequireAdmin>
							<Coordinators />
						</RequireAdmin>
					),
				},
				{
					path: '/grupos/:id',

					element: (
						<OwnsGroup>
							<GroupMembers />
						</OwnsGroup>
					),
				},
				{
					path: '/listado-grupos',
					element: <GroupsList />,
				},
				{
					path: '/tipos-grupo',
					element: (
						<RequireAdmin>
							<GroupTypes />
						</RequireAdmin>
					),
				},
				{
					path: '/estados',
					element: (
						<RequireAdmin>
							<States />
						</RequireAdmin>
					),
				},
				{
					path: '/cambiar-contraseña',
					element: <ChangePassword />,
				},
			],
		},
		{
			path: '/restorePassword/:hash',
			element: <RestorePassword />,
		},
	]);

	return (
		<div className="App">
			<RouterProvider router={router} />
		</div>
	);
}

export default App;
