import * as React from 'react';
import AppBar from '@mui/material/AppBar';

import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useUser } from '../../contexts/userContext';
import { Divider, ListItemIcon, Menu, MenuItem } from '@mui/material';
import { Logout } from '@mui/icons-material';
import { useCloseSession } from '../../requests/close-session';
import { Link } from 'react-router-dom';

interface Props {
	handleDrawerToggle: () => void;
	drawerWidth: number;
}

export default function NavBar({ handleDrawerToggle, drawerWidth }: Props) {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const user = useUser();
	const userName = user?.name.split(' ')[0];

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const closeSession = useCloseSession();

	return (
		<>
			<AppBar
				color="secondary"
				position="fixed"
				sx={{
					width: { sm: `calc(100% - ${drawerWidth}px)` },
					ml: { sm: `${drawerWidth}px` },
					display: 'flex',
					justifyContent: 'space-between',
					flexDirection: 'row',
					color: 'white',
				}}
			>
				<Toolbar>
					<IconButton
						color="inherit"
						aria-label="open drawer"
						edge="start"
						onClick={handleDrawerToggle}
						sx={{ mr: 2, display: { sm: 'none' } }}
					>
						<MenuIcon />
					</IconButton>
				</Toolbar>
				<div style={{ display: 'flex', marginRight: '2rem' }}>
					{user && (
						<Typography
							noWrap
							component="div"
							sx={{ alignSelf: 'center', mr: 0.5 }}
							className="no-select"
						>
							Hola, <b>{userName}</b>!
						</Typography>
					)}
					<div style={{ alignSelf: 'center' }}>
						<IconButton
							color="inherit"
							aria-label="open user menu"
							aria-controls={open ? 'account-menu' : undefined}
							aria-haspopup="true"
							onClick={handleClick}
							aria-expanded={open ? 'true' : undefined}
						>
							<PersonIcon />
						</IconButton>
					</div>
				</div>
			</AppBar>

			<Menu
				anchorEl={anchorEl}
				id="account-menu"
				open={open}
				onClose={handleClose}
				onClick={handleClose}
				title="Opciones"
				PaperProps={{
					elevation: 0,

					sx: {
						overflow: 'visible',
						filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
						mt: 1.5,
						'& .MuiAvatar-root': {
							width: 32,
							height: 32,
							ml: -0.5,
							mr: 1,
						},
						'&:before': {
							content: '""',
							display: 'block',
							position: 'absolute',
							top: 0,
							right: 14,
							width: 10,
							height: 10,
							bgcolor: 'background.paper',
							transform: 'translateY(-50%) rotate(45deg)',
							zIndex: 0,
						},
					},
				}}
				transformOrigin={{ horizontal: 'right', vertical: 'top' }}
				anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
			>
				<MenuItem>
					<Link
						to="/cambiar-contraseña"
						style={{
							textDecoration: 'none',
							color: 'inherit',
							display: 'flex',
							alignItems: 'center',
						}}
					>
						<ListItemIcon>
							<LockIcon fontSize="small" />
						</ListItemIcon>
						Cambiar contraseña
					</Link>
				</MenuItem>
				<Divider />
				<MenuItem onClick={async () => await closeSession()}>
					<ListItemIcon>
						<Logout fontSize="small" />
					</ListItemIcon>
					Cerrar sesión
				</MenuItem>
			</Menu>
		</>
	);
}
